import React, { useState, useEffect } from 'react';
import './Counter.css';
function Counter() {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(prevCount => prevCount + 1);
        }, 100);
        if (count >= 100) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [count]);

    return (
        <div>
            <div style={{ marginTop: '10px' }}>
                <h3>Loading ... {count}%</h3>
            </div>
        </div>

    );
}

export default Counter;
