import React, { useState, useEffect } from "react";
import { Table } from "./Components/Table";

function App() {

  const [token, setToken] = useState('');

  useEffect(() => {
    const url = document.URL;
    const token = url.split("token=")[1];

    setToken(token);

    if (token) {
      localStorage.setItem("token", token);
    }

  }, []);

  return (
    <div className="App">

      {/* Use this table for hosting purpose */}

      {
        token ?
          <Table />
          :
          <h1 style={{ marginTop: '20%' }}>Token not found</h1>
      }

      {/* ? Use this table for locally testing purpose */}

      {/* <Table /> */}
    </div>

  );
}

export default App;
