import axios from 'axios';

export const BASE_URL = 'https://rqs.nassa.site/'; // Live server
// export const BASE_URL = 'http://192.168.10.201:8000/'; // local server


// ? Use this function, when app is going to live server

const getToken = () => {
    const url = document.URL;
    const token = url.split("token=")[1];
    if (token) {
        localStorage.setItem("token", token);
    }
    return token;
};

const token = getToken();

// const token = "10000|d8rLPRXtXmqZA8sGmFGWutaNgR0sRCgufiZIXPvf" // ? Use that token for locally testing purpose

export const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token
}

export default axios.create({
    baseURL: BASE_URL,
    headers: headers
});


export async function Post(apiEndPoint, data) {
    return await axios.post(`${BASE_URL}${apiEndPoint}`, data, {
        headers: headers
    });

}

export async function Get(apiEndPoint) {
    return await axios.get(`${BASE_URL}${apiEndPoint}`, {
        headers: headers
    });
}
