/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Get, Post } from "../http/http.js";
import { TableSection } from "./TableSection.jsx";
import LoadingSpinner from './../Spinner/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Button, TextField, Tooltip } from "@mui/material";


const toastOptions = {
  position: "bottom-center",
  autoClose: 2000,
  pauseOnHover: true,
  draggable: true,
  theme: "colored",
};

export const Table = () => {

  // *All States Here
  const [pendingRequisition, setPendingRequisition] = useState([]);
  const [pendingProduct, setPendingProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState('');
  const [isMasterChecked, setIsMasterChecked] = useState(false);


  // *All Handlers Here
  const handleReason = (e) => { setReason(e.target.value); };


  // convert selectedItems to string 
  let selectedItemsString = selectedItems ? selectedItems.toString() : '';


  // !All useEffects Here
  useEffect(() => {
    getRQSData();
  }, []);

  // *All Functions Here
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  // Get All Pending Requisitions
  const getRQSData = async () => {
    setIsLoading(true);
    try {
      await Get('api/RequestDashboard')
        .then(res => {
          if (res.status === 200) {
            // console.log("🚀 ~ file: Table.jsx:61 ~ getRQSData ~ res", res.data)
            if (res.data.pending_requisition.length === 0) {
              toast.info("No Pending Requisition", toastOptions);
            }
            setPendingRequisition(res.data.pending_requisition)
            setPendingProduct(res.data.pending_products)
            setIsLoading(false);
          }
        })
    } catch (error) {
      setIsLoading(false);
      console.log("Fetch Error", error);
    }
  };

  // Approve All Requisition
  function handleMasterCheckboxChange(event) {
    const isChecked = event.target.checked;
    setIsMasterChecked(isChecked);
    setPendingRequisition(pendingRequisition.map(item => {
      return { ...item, isChecked: isChecked };
    }));

    if (isChecked) {
      setSelectedItems(pendingRequisition.map(item => item.requisition_id));
    } else {
      setSelectedItems('');
    }
  };

  // Approve Single Requisition
  function handleSingleCheckboxChange(itemId) {
    const newPendingRequisition = pendingRequisition.map(item => {
      if (item.requisition_id === itemId) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });
    setPendingRequisition(newPendingRequisition);
    const newSelectedItems = selectedItems.includes(itemId)
      ? selectedItems.filter(id => id !== itemId)
      : [...selectedItems, itemId];
    setSelectedItems(newSelectedItems);

    if (newSelectedItems.length === pendingRequisition.length) {
      setIsMasterChecked(true);
    } else {
      setIsMasterChecked(false);
    }
  }

  // Approve RQS
  const ApproveRQS = async () => {
    if (selectedItemsString === '') {
      toast.error("Please Select At Least One Item", toastOptions);
      return;
    }
    const sendItem = selectedItemsString ? selectedItemsString : '';
    // console.log("🚀 ~ file: Table.jsx:154 ~ ApproveRQS ~ sendItem", sendItem)
    if (window.confirm('Are you sure you want to approve this requisition?')) {
      try {
        await Post(`api/ApproveRequest?requisition_id=${sendItem}`)
          .then(res => {
            // console.log("Approve Rqs res -->> ", res)
            if (res.status === 200) {
              toast.success("Approve Rqs Done", toastOptions);
              setSelectedItems('');
              // After approve remove selected items from table
              setPendingRequisition(pendingRequisition.filter(item => !selectedItems.includes(item.requisition_id)));
              setIsMasterChecked(false);
            }
          })
      } catch (error) {
        console.log("Approve Rqs Error", error);
      }
    } else {
      return;
    }
  };

  // Approve With RQS
  const ApproveRQSWithCS = async () => {
    if (selectedItemsString === '') {
      toast.error("Please Select At Least One Item", toastOptions);
      return;
    }
    const sendItem = selectedItemsString ? selectedItemsString : '';
    // console.log("🚀 ~ file: Table.jsx:179 ~ ApproveRQSWithCS ~ sendItem", sendItem)
    if (window.confirm('Are you sure you want to approve this requisition With CS?')) {
      try {
        await Post(`api/ApproveRequestWithCS?requisition_id=${sendItem}`)
          .then(res => {
            // console.log("Approve Rqs With CS res -->> ", res)
            if (res.status === 200) {
              toast.success("Approve Rqs With CS Done", toastOptions);
              setSelectedItems('');
              // After approve remove selected items from table
              setPendingRequisition(pendingRequisition.filter(item => !selectedItems.includes(item.requisition_id)));
              setIsMasterChecked(false);
            }
          })
      } catch (error) {
        console.log("Approve Rqs With CS Error", error);
      }
    } else {
      return;
    }
  };

  // Reject RQS
  const RejectRQS = async () => {
    if (selectedItemsString === '') {
      toast.error("Please Select At Least One Item", toastOptions);
      return;
    }
    const sendItem = selectedItemsString ? selectedItemsString : '';
    // console.log("🚀 ~ file: Table.jsx:167 ~ RejectRQS ~ sendItem", sendItem)

    if (window.confirm('Are you sure you want to reject this requisition?')) {
      try {
        Post(`api/RejectRequest?requisition_id=${sendItem}&reason=${reason}`)
          .then(res => {
            // console.log("Reject Rqs res -->> ", res)
            if (res.status === 200) {
              toast.success("Reject Rqs Done", toastOptions);
              setSelectedItems('');
              // After approve remove selected items from table
              setPendingRequisition(pendingRequisition.filter(item => !selectedItems.includes(item.requisition_id)));
              setIsMasterChecked(false);
              setReason('');
              handleClose();
            }
          })
      } catch (error) {
        console.log("Reject Rqs Error", error);
      }
    } else {
      return;
    }
  };


  return (
    <div style={{ width: '100%' }}>

      <div style={{ height: '60px', width: '100%', padding: '10px', backgroundColor: '#fff', display: 'flex', justifyContent: 'space-between', position: 'fixed', zIndex: '1' }} >

        <div style={{ marginLeft: '20px' }}>

          <Tooltip title="RQS Approve" placement="bottom-end">
            <Button
              variant="contained"
              color="success"
              onClick={() => ApproveRQS()}
            >
              RQS Approve
            </Button>
          </Tooltip>

          <Tooltip title="RQS With CS Approve" placement="bottom-end">
            <Button
              variant="contained"
              color="success"
              style={{ marginLeft: "10px" }}
              onClick={() => ApproveRQSWithCS()}
            >
              RQS With CS Approve
            </Button>
          </Tooltip>

          <Tooltip title="RQS Reject" placement="bottom-end">
            <Button
              variant="contained"
              color="error"
              style={{ marginLeft: "10px" }}
              // onClick={() => RejectRQS()}
              onClick={handleOpen}
            >
              RQS Reject
            </Button>
          </Tooltip>

        </div>

        <div>

          <input
            type="search"
            placeholder="Search Item ..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            style={{ width: '300px', height: '40px', padding: '10px', borderRadius: '5px', border: '1px solid #ededed' }}
          />

        </div>

      </div>

      {
        isLoading
          ?
          <LoadingSpinner />
          :
          <>
            <table className="table" style={{ marginTop: '50px', padding: '10px' }}>

              <thead style={{ textAlign: 'center' }} className="thead">

                <tr>

                  {/* <th className="th"></th>

                  <th className="th"></th> */}

                  <th className="th"></th>

                  <th className="th" style={{ padding: '5px' }}>

                    <input
                      type="checkbox"
                      checked={isMasterChecked}
                      onChange={handleMasterCheckboxChange}
                    />

                  </th>

                  <th style={{ width: '100px' }}>Req. ID</th>

                  <th className="th">Company</th>

                  <th className="th">Number of Item </th>

                  <th style={{ width: '200px' }}>Requisition Description</th>

                  <th className="th">Employee</th>

                  <th className="th">Department</th>

                  <th className="th">Created</th>

                  <th className="th">Pending</th>

                  <th className="th">CS Status </th>

                  <th className="th">Attachment</th>

                  <th className="th">Approved Requisition Amount</th>

                  <th className="th">Action</th>
                  <th className="th"></th>
                  <th className="th"></th>

                </tr>
              </thead>

              {
                pendingRequisition

                  .filter((item) => {

                    if (searchTerm === '') {

                      return item;

                    } else if (

                      item.requisition_id.toLowerCase().includes(searchTerm.toLowerCase()) ||

                      item.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||

                      item.comments.toLowerCase().includes(searchTerm.toLowerCase()) ||

                      item.emp_name.toLowerCase().includes(searchTerm.toLowerCase()) ||

                      item.dp_name.toLowerCase().includes(searchTerm.toLowerCase()) ||

                      moment(item.created_at).format('DD-MM-YYYY').toLowerCase().includes(searchTerm.toLowerCase()) ||

                      moment(item.created_at).fromNow().toLowerCase().includes(searchTerm.toLowerCase()) ||

                      pendingProduct

                        .filter((id) => id.requisition_id === item.requisition_id)

                        .map((item) => Number(item.quotation_price) * Number(item.quantity))

                        .reduce((a, b) => a + b, 0).toString().toLowerCase().includes(searchTerm.toLowerCase())

                    ) {
                      return item;
                    }

                  })
                  .map((item, index) => (

                    <TableSection

                      item={item}

                      index={index}

                      pendingProduct={pendingProduct}

                      handleSingleCheckboxChange={handleSingleCheckboxChange}

                      getRQSData={getRQSData}

                      setPendingProduct={setPendingProduct}

                      searchTerm={searchTerm}

                    />

                  ))}

            </table>

          </>
      }

      {/* Reject RQS Modal */}

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30%',
          maxWidth: '50%',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '10px',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reject Requisition
            <CloseIcon style={{ cursor: 'pointer', float: 'right', color: 'red' }} onClick={() => handleClose()} />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 3, mb: 2 }}>
            <TextField
              id="outlined-basic"
              label="Reason"
              variant="outlined"
              style={{ width: '100%' }}
              value={reason}
              onChange={(e) => handleReason(e)}
            />
          </Typography>

          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginTop: '10px', float: 'right' }}
            // onClick={handleRejectRequest}
            onClick={() => RejectRQS()}
          >
            Submit
          </Button>
        </Box>
      </Modal>

      <ToastContainer />

    </div>
  );
};
