/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { ExpendableButton } from "./ExpendableButton";
import { TableRow } from "./TableRow";
import useOpenController from "../Hooks/useOpenController";
import moment from "moment";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { BASE_URL, Post } from "../http/http";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Button, TextField, Tooltip } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const toastOptions = {
  position: "bottom-center",
  autoClose: 2000,
  pauseOnHover: true,
  draggable: true,
  theme: "colored",
};

export const TableSection = ({ index, item, pendingProduct, setPendingProduct, handleSingleCheckboxChange, getRQSData }) => {


  const { isOpen, toggle } = useOpenController(false);
  const csStatus = Number(item.cs_status_okay);
  const itemCount = Number(item.item_count);
  const matchItem = csStatus === itemCount;
  const getCsAge = moment(item.last_approve_time ? item.last_approve_time : item.created_at).fromNow();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [reason, setReason] = useState("");

  const handleReason = (e) => {
    setReason(e.target.value);
  };

  const handleRejectRequest = async () => {
    const Id = Number(item.requisition_id);
    console.log("ID", Id)
    if (window.confirm("Are you sure you want to delete this requisition?")) {
      try {
        Post(`api/RejectRequestById?requisition_id=${Id}&reason=${reason}`)
          .then(res => {
            console.log("res", res)
            if (res.status === 200) {
              toast.success("Request Rejected Successfully", toastOptions);
              // setPendingProduct(pendingProduct.filter(item => Number(item.requisition_id) !== Id));
              handleClose();
              setReason("");
              getRQSData();
            }
          })
      } catch (error) {
        console.log("Reject Error ----->>> ", error)
      }
    } else {
      return false;
    }
  };

  const handleApproveCS = async (id) => {
    const Id = Number(id)
    if (window.confirm("Are you sure you want to approve this requisition?")) {
      try {
        Post(`api/ApproveRequest?requisition_id=${Id}`)
          .then(res => {
            if (res.status === 200) {
              toast.success("Request Approved Successfully", toastOptions);
              setPendingProduct(pendingProduct.filter(item => item.requisition_id !== id));
            }
          })
      } catch (error) {
        console.log("Approve Error ----->>> ", error)
      }
    } else {
      return false;
    }
  };

  // convert date to number of days
  const getDays = (date) => {
    const today = new Date();
    const date1 = new Date(date);
    const diffTime = Math.abs(today - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const getCsAgeNumber = getDays(item.last_approve_time ? item.last_approve_time : item.created_at);

  // 1st Step, Check requisition id is same or not
  // 2nd Step, Check approve_currency is same or not which matched with requisition id
  // 3rd Step, if approve_currency is same then multiply with quantity and quotation_price
  // 4th Step, if approve_currency is not same then show error message

  const getTotalAmount = () => {
    const checkSameID = pendingProduct.filter((id) => id.requisition_id === item.requisition_id && id.approve_currency !== null);
    var itemCount = checkSameID.length;

    const getCurrencyName = checkSameID.map((currency, index) => {
      // show index 0 value
      if (index === 0) {
        var currencyName = currency.approve_currency;
        const currencyCheckLength = checkSameID.filter((id) => id.approve_currency === currencyName).length;

        // console.log('Curreny lenght', currencyCheckLength);

        if (itemCount !== currencyCheckLength) {
          return <small>
            <Tooltip title="Currency Mismatch" placement="bottom-end">
              <WarningAmberIcon
                style={{ cursor: 'pointer', color: 'yellow', marginRight: '10px', borderRadius: '10px' }}
              />
            </Tooltip>
          </small>
        } else {
          const totalAmount =
            checkSameID
              .filter((id) => id.requisition_id === item.requisition_id)                    // filter by requisition id
              .map((item) => Number(item.quotation_price) * Number(item.quantity))          // map price and quantity
              .reduce((a, b) => a + b, 0);                                                  // sum up the total price

          return <small>
            {
              currencyName === 'USD' ? '$' :
                currencyName === 'BDT' ? '৳' :
                  currencyName === 'EUR' ? '€' : null
            } {totalAmount}
          </small>
        }
      }
    })
    return getCurrencyName;
  }
  const totalAmount = getTotalAmount();

  return (
    <>
      <tbody style={{ textAlign: 'center' }}>
        <tr
          style={{
            border: '1px solid #e6e6e6',
            backgroundColor: getCsAgeNumber <= 3 ? '#D9D9D9' : getCsAgeNumber <= 6 ? '#FFEC3A' : getCsAgeNumber <= 14 ? '#FF9E0F' : getCsAgeNumber > 14 ? '#FE0000' : '',
            color: getCsAgeNumber <= 3 ? '#242424' : getCsAgeNumber <= 6 ? '#100D00' : getCsAgeNumber <= 14 ? '#fff' : getCsAgeNumber > 14 ? '#FFF9FF' : '',
          }}
          key={index}
        >
          <td className="button-td">
            <ExpendableButton isOpen={isOpen} toggle={toggle} />
          </td>
          <td>
            <input
              type="checkbox"
              checked={item.isChecked}
              onChange={() => handleSingleCheckboxChange(item.requisition_id)}
            />
          </td>
          <td>{item.requisition_id}</td>
          <td>{item.company_name}</td>
          <td>{item.item_count}</td>
          <td>{item.comments}</td>
          <td>{item.emp_name}</td>
          <td>{item.dp_name}</td>
          <td>{moment(item.created_at).format('DD-MM-YYYY')}</td>
          <td>{getCsAge}</td>
          <td>
            {
              matchItem === true ?
                <Tooltip title="CS Status OK" placement="bottom-end">
                  <TaskAltIcon style={{ color: getCsAgeNumber > 14 ? '#fff' : 'green' }} />
                </Tooltip>
                :
                <Tooltip title="CS Status Not OK" placement="bottom-end">
                  <UnpublishedOutlinedIcon style={{
                    color: getCsAgeNumber > 14 ? '#fff' : 'red'
                  }} />
                </Tooltip>
            }
          </td>
          <td>
            <a href={`${BASE_URL}approval_view/${item.attachment}`}
              target="_blank"
              title={item.attachment}
              rel="noreferrer">
                
                 <PictureAsPdfIcon style={{ color: '#fff' }}/>
        
                </a>
          </td>
          <td>{totalAmount}</td>
          <td>
            <Tooltip title="Approve Requisition" placement="bottom-end">
              <CheckCircleOutlineOutlinedIcon
                style={{ cursor: 'pointer', color: getCsAgeNumber > 14 ? '#fff' : 'green', marginRight: '10px', borderRadius: '10px' }}
                onClick={() => handleApproveCS(item.requisition_id)}
              />
            </Tooltip>
            <Tooltip title="Reject Requisition" placement="bottom-end">
              <DeleteOutlineIcon
                style={{ cursor: 'pointer', color: getCsAgeNumber > 14 ? '#fff' : 'red', marginRight: '10px', borderRadius: '10px' }}
                // onClick={() => handleRejectRequest(item.requisition_id)}
                onClick={handleOpen}
              />
            </Tooltip>
          </td>
           <td></td>
          <td></td>
        </tr>
        {
          isOpen &&

          <>
            <>
              <tr style={{ backgroundColor: '#EDE8E8' }} key={index}>
                <th style={{ borderBottom: '1px solid black' }}>Item</th>
                <th style={{ borderBottom: '1px solid black' }}>Specification</th>
                <th style={{ borderBottom: '1px solid black' }}>ETA</th>
                <th style={{ borderBottom: '1px solid black' }}>Unit</th>
                <th style={{ borderBottom: '1px solid black' }}>Quantity</th>
                <th style={{ borderBottom: '1px solid black' }}>Remarks</th>
                <th style={{ borderBottom: '1px solid black' }}>Last Purchase Date</th>
                <th style={{ borderBottom: '1px solid black' }}>Last Purchase Price</th>
                <th style={{ borderBottom: '1px solid black' }}>Approximately Unit Price</th>
                <th style={{ borderBottom: '1px solid black' }}>Approved Unit Price</th>
                <th style={{ borderBottom: '1px solid black' }}>CS Status</th>
                <th style={{ borderBottom: '1px solid black' }}>View CS</th>
                <th style={{ borderBottom: '1px solid black' }}>Approximate Requisition Value</th>
                <th style={{ borderBottom: '1px solid black' }}>Approved Total Value</th>
                <th style={{ borderBottom: '1px solid black' }}>Pending</th>
                <th style={{ borderBottom: '1px solid black' }}>Action</th>
              </tr>
            </>
            <>
              {pendingProduct.map((pendingItem, index) => (
                <TableRow
                  pendingItem={pendingItem}
                  id={item}
                  index={index}
                  getRQSData={getRQSData}
                />
              ))}
            </>
          </>
        }
        <ToastContainer />
      </tbody>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30%',
          maxWidth: '50%',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '10px',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reject Requisition
            <CloseIcon style={{ cursor: 'pointer', float: 'right', color: 'red' }} onClick={() => handleClose()} />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 3, mb: 2 }}>
            <TextField
              id="outlined-basic"
              label="Reason"
              variant="outlined"
              style={{ width: '100%' }}
              value={reason}
              onChange={(e) => handleReason(e)}
            />
          </Typography>

          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginTop: '10px', float: 'right' }}
            onClick={handleRejectRequest}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
};
