import React, { useState } from "react";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { BASE_URL, Get, Post } from "../http/http";
import Counter from "../Spinner/Counter";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Button, TextField, Tooltip } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from '@mui/material/FormHelperText';
import moment from "moment/moment";


export const TableRow = ({ pendingItem, id, index, getRQSData }) => {

  console.log("Pending Item ----------->>>> ", pendingItem)

  const toastOptions = {
    position: "bottom-center",
    autoClose: 2000,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  };

  // All States Here

  const [isLoading, setIsLoading] = useState(false);
  const [csView, setCsView] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [csPriceTarget, setCsPriceTarget] = useState(0);
  const [priceCheck, setPriceCheck] = useState(false);
  const [reason, setReason] = useState("");
  const [approvedHistory, setApprovedHistory] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);

  // All Variable Here

  const matchItem = pendingItem.requisition_id === id.requisition_id;
  const approxPrice = Number(pendingItem.aprox_price);
  const approximateRequisitionValue = pendingItem.quantity * approxPrice;
  const quotationPrice = Number(pendingItem.quotation_price);
  const approveTotalPrice = pendingItem.quantity * quotationPrice;
  const approxCsId = Number(pendingItem.aprox_cs_id);
  const approveCsId = Number(pendingItem.approve_cs_id);

  // All Handler Here

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value)
  };

  const handlePriceCheck = (e) => {
    setPriceCheck(e.target.checked)
  };

  const handleCSPriceTarget = (e) => {
    setCsPriceTarget(e.target.value)
  };

  const handleReason = (e) => {
    setReason(e.target.value);
  };


  // All Functions Here

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenModal2 = () => setOpenModal2(true);
  const handleCloseModal2 = () => setOpenModal2(false);

  const handleOpenModal3 = () => setOpenModal3(true);
  const handleCloseModal3 = () => setOpenModal3(false);

  const handleOpenModal4 = () => setOpenModal4(true);
  const handleCloseModal4 = () => setOpenModal4(false);


  // CS View Modal
  const getCsView = async (approxCsId, approveCsId) => {
    setIsLoading(true);
    try {
      await Get(`api/CsView/${approxCsId || approveCsId}`)
        .then(res => {
          console.log("CsView  -->>> ", res)
          setCsView(res.data)
          setIsLoading(false);
        })
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  // Approve CS Modal
  const approveCS = async (id) => {
    const Id = Number(id)
    try {
      await Post(`api/ApproveCs?quotation_id=${Id}`)
        .then(res => {
          console.log("Approve CS Done -->> ", res)
          if (res.status === 200) {
            getRQSData()
          }
        })
    } catch (error) {
      console.log("Approve CS Error -->> ", error);
    }
  };

  // Price Check
  const checkPrice = (approxPrice, quotationPrice) => {
    if (approxPrice === 0 && quotationPrice === 0) {
      return false
    } else {
      return true
    }
  }

  // Approve Item CS
  const handleApproveItemCS = async (id) => {
    const Id = Number(id)
    if (window.confirm("Are you sure to approve this item?")) {
      try {
        await Post(`api/ApproveRequestByItemID?item_id=${Id}`)
          .then(res => {
            console.log("Approve CS Done 133 Line -->> ", res)
            if (res.status === 200) {
              getRQSData()
            }
          })
      } catch (error) {
        console.log("Approve CS Error -->> ", error);
      }
    } else {
      return;
    }
  };

  // Reject Item CS
  const handleRejectItemCS = () => {
    const Id = Number(pendingItem.id)
    // console.log("handleRejectItemCS -->> ", Id)
    if (window.confirm("Are you sure to reject this item?")) {
      Post(`api/RejectRequestByItemId?requisition_item_id=${Id}&reason=${reason}`)
        .then(res => {
          console.log("Reject CS Done 133 Line -->> ", res)
          if (res.status === 200) {
            getRQSData();
            handleCloseModal3();
            toast.success("Item Rejected Successfully", toastOptions);
            setReason("");
          }
        })
    } else {
      return;
    }
  };

  // Quantity Update
  const handleQuantityUpdate = (quantity) => {
    setQuantity(quantity)
  };

  // Edit Quantity
  const handleEditQuantity = () => {
    // console.log("Edit Quantity")
    const Quantity = quantity;
    const ItemId = pendingItem.id;
    // console.log("🚀 ~ file: TableRow.jsx:185 ~ handleEditQuantity ~ ItemId", ItemId)
    try {
      Post(`api/update_product_quantity?item_id=${ItemId}&quantity=${Quantity}`)
        .then(res => {
          console.log("Update Quantity Done -->> ", res)
          if (res.status === 200) {
            handleCloseModal()
            pendingItem.quantity = Quantity
            toast.success("Quantity Updated Successfully", toastOptions)
          }
        })
    } catch (error) {
      console.log("Update Quantity Error -->> ", error);
    }
  };

  // Set Target Price
  const handleTargetPrice = () => {
    // console.log("Edit Quantity")
    const TargetPrice = csPriceTarget ? csPriceTarget : null;
    // console.log("🚀 ~ file: TableRow.jsx:188 ~ handleTargetPrice ~ TargetPrice", TargetPrice)

    // get cs id from csView
    const csId = csView[0].cs_id;

    console.log("🚀 ~ file: TableRow.jsx:190 ~ handleTargetPrice ~ csId", csId)
    try {
      Get(`api/set_cs_revise?cs_id=${csId}&cs_price_target=${TargetPrice}`)
        .then(res => {
          console.log("Target Price Done -->> ", res)
          if (res.status === 200) {
            handleCloseModal2()
            handleClose()
            setPriceCheck(false)
            setCsPriceTarget('')
            pendingItem.aprox_price = 0
            toast.success("Target Price Set Successfully", toastOptions)
          }
        })
    } catch (error) {
      console.log("Target Price Error -->> ", error);
    }
  };

  // Approve With Minimum Price
  const approveWithMinimumPrice = () => {
    const getLowPrice = csView.map(item => Number(item.quotation_price))
    // console.log("🚀 ~ file: TableRow.jsx:214 ~ approveWithMinimumPrice ~ getLowPrice", getLowPrice)

    const lowPrice = Math.min(...getLowPrice)
    // console.log("🚀 ~ file: TableRow.jsx:216 ~ approveWithMinimumPrice ~ lowPrice", lowPrice)

    const csId = csView.map(item => item.quotation_price_id)
    // console.log("🚀 ~ file: TableRow.jsx:218 ~ approveWithMinimumPrice ~ csId", csId)

    const csIdIndex = getLowPrice.indexOf(lowPrice)
    // console.log("🚀 ~ file: TableRow.jsx:220 ~ approveWithMinimumPrice ~ csIdIndex", csIdIndex)

    const csIdLowPrice = csId[csIdIndex]
    // console.log("🚀 ~ file: TableRow.jsx:222 ~ approveWithMinimumPrice ~ csIdLowPrice", csIdLowPrice)

    try {
      Post(`api/ApproveCs?quotation_id=${csIdLowPrice}`)
        .then(res => {
          console.log("Approve With Minimum Price Done -->> ", res)
          if (res.status === 200) {
            getRQSData()
            toast.success("Successfully Approve With Minimum Price", toastOptions)
          }
        })
    } catch (error) {
      console.log("Approve With Minimum Price Error -->> ", error);
    }
  };

  // Get Approved History
  const getApprovedHistory = async (id) => {
    // console.log("getApprovedHistory Id -->> ", id)
    setIsLoading(true)
    try {
      Get(`api/get_approval_log?item_id=${id}`)
        .then(res => {
          console.log("getApprovedHistory Done -->> ", res.data.data)
          if (res.status === 200) {
            setApprovedHistory(res.data.data)
            setIsLoading(false)
          }
        })
    } catch (error) {
      console.log("getApprovedHistory Error -->> ", error);
      setIsLoading(false);
    }
  }


  return (
    <>
      <tr key={index} style={{ backgroundColor: '#EDE8E8' }}>
        {
          matchItem && (
            <>
              <td style={{ borderBottom: '1px solid black', width: '10%' }}>{pendingItem.product_name} - {pendingItem.product_code}</td>
              <td style={{ borderBottom: '1px solid black' }}>{pendingItem.specification}</td>
              <td style={{ borderBottom: '1px solid black' }}>{pendingItem.eta}</td>
              <td style={{ borderBottom: '1px solid black' }}>{pendingItem.unit}</td>
              <Tooltip title="Update Quantity" placement="bottom">
                <td
                  style={{ borderBottom: '1px solid black', padding: '10px', cursor: 'pointer' }}
                  onClick={() => { handleOpenModal(pendingItem.id); handleQuantityUpdate(pendingItem.quantity) }}>
                  {pendingItem.quantity}
                </td>
              </Tooltip>
              <td style={{ borderBottom: '1px solid black' }}>{pendingItem.remarks}</td>
              <td style={{ borderBottom: '1px solid black' }}>
                {pendingItem.last_purchase_date ? moment(pendingItem.last_purchase_date).format("DD-MM-YYYY") : null}
              </td>
              <td style={{ borderBottom: '1px solid black' }}>
                {pendingItem.last_purchase_price}
              </td>
              <td style={{ borderBottom: '1px solid black' }}>
                {
                  pendingItem.aprox_currency === 'USD' ? '$' :
                    pendingItem.aprox_currency === 'BDT' ? '৳' :
                      pendingItem.aprox_currency === 'EUR' ? '€' : null
                }  {approxPrice}
              </td>
              <td style={{ borderBottom: '1px solid black' }}>
                {
                  pendingItem.approve_currency === 'USD' ? '$' :
                    pendingItem.approve_currency === 'BDT' ? '৳' :
                      pendingItem.approve_currency === 'EUR' ? '€' : null
                }  {quotationPrice}
              </td>
              <td style={{ borderBottom: '1px solid black' }}>
                {
                  pendingItem.quotation_price !== null ?
                    <TaskAltIcon style={{ color: 'green' }} />
                    :
                    <UnpublishedOutlinedIcon style={{ color: 'red' }} />
                }
              </td>
              <td style={{ borderBottom: '1px solid black' }}>
                {
                  checkPrice(approxPrice, quotationPrice) ?
                    <RemoveRedEyeOutlinedIcon
                      onClick={() => { handleOpen(approxCsId); getCsView(approxCsId, approveCsId) }}
                      style={{ cursor: 'pointer', color: 'green' }}
                    />
                    :
                    null
                }
              </td>
              <td style={{ borderBottom: '1px solid black' }}>{
                pendingItem.aprox_currency === 'USD' ? '$' :
                  pendingItem.aprox_currency === 'BDT' ? '৳' :
                    pendingItem.aprox_currency === 'EUR' ? '€' : null
              } {approximateRequisitionValue}</td>
              <td style={{ borderBottom: '1px solid black' }}>{
                pendingItem.approve_currency === 'USD' ? '$' :
                  pendingItem.approve_currency === 'BDT' ? '৳' :
                    pendingItem.approve_currency === 'EUR' ? '€' : null
              } {approveTotalPrice}</td>
              <td style={{ borderBottom: '1px solid black' }}>
                {moment(pendingItem.last_approve_time ? pendingItem.last_approve_time : pendingItem.created_at).fromNow()}
              </td>
              <td style={{ borderBottom: '1px solid black' }}>

                <Tooltip title="Approve this item" placement="bottom-end">
                  <CheckCircleOutlineOutlinedIcon
                    style={{ cursor: 'pointer', color: 'green', marginRight: '10px', borderRadius: '10px' }}
                    onClick={() => handleApproveItemCS(pendingItem.id)}
                  />
                </Tooltip>

                <Tooltip title="View approved history" placement="bottom-end">
                  <RemoveRedEyeOutlinedIcon
                    style={{ cursor: 'pointer', color: 'green', marginRight: '10px', borderRadius: '10px' }}
                    onClick={() => { handleOpenModal4(pendingItem.id); getApprovedHistory(pendingItem.id) }}
                  />
                </Tooltip>

                <Tooltip title="Reject this item" placement="bottom-end">
                  <DeleteOutlineIcon
                    style={{ cursor: 'pointer', color: 'red', marginRight: '10px', borderRadius: '10px' }}
                    // onClick={() => handleRejectItemCS(pendingItem.id)}
                    onClick={() => handleOpenModal3(pendingItem.id)}
                  />
                </Tooltip>
              </td>

            </>
          )
        }
        <ToastContainer />
      </tr>

      {
        isLoading ?
          <Box style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            padding: '20px',
            backgroundColor: '#fff',
          }}>

            <Counter />
          </Box>
          : <Modal
            open={open}
            onClose={handleClose}
          >
            <Box style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              maxWidth: '60%',
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '10px',
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Supplier Price Quotation
                <CloseIcon style={{ cursor: 'pointer', float: 'right', color: 'red' }} onClick={() => handleClose()} />
              </Typography>
              <Typography id="modal-modal-description" sx={{ mb: 5 }}>

                <table className="table">
                  <thead style={{ textAlign: 'center' }} className="thead">
                    <th>Item Description</th>
                    <th>Supplier</th>
                    <th>Brand</th>
                    <th>Origin</th>
                    <th>Supply From</th>
                    <th>Payment Term</th>
                    <th>Total Lead Time</th>
                    <th>Currency</th>
                    <th>Quoted Price</th>
                    <th>Unit Price</th>
                    <th>Total Price</th>
                    {approxPrice ?
                      <th>Action</th>
                      : null
                    }
                    <th>Attachment</th>
                  </thead>
                  <tbody style={{ textAlign: 'center' }} className="tbody">
                    {
                      csView.map((csItem, index) => (
                        <tr key={index}>
                          <td>{csItem.product_name} - {csItem.product_details}</td>
                          <td>{csItem.supplier_name}</td>
                          <td>{csItem.brand}</td>
                          <td>{csItem.origin}</td>
                          <td>{csItem.supply_from}</td>
                          <td>{csItem.payment_term}</td>
                          <td>{csItem.total_lead_time}</td>
                          <td>{csItem.currency}</td>
                          <td>{csItem.quoted_price}</td>
                          <td>{csItem.quotation_price}</td>
                          <td>{pendingItem.quantity * csItem.quotation_price}</td>
                          {approxPrice ?
                            <td>
                              <TaskAltIcon
                                style={{ cursor: 'pointer', color: 'green' }}
                                onClick={() => approveCS(csItem.quotation_price_id)}
                              />
                            </td>
                            : null
                          }
                          <td>
                            <a
                              href={`${BASE_URL}doc/cs/${csItem.file}`}
                              target="_blank"
                              rel="noreferrer"
                            >{csItem.file}</a>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                <br />
                {approxPrice ? <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginRight: '10px', marginTop: '10px' }}
                  onClick={() => handleOpenModal2(id)}
                >
                  Send back for revise
                </Button> : null
                }

                {approxPrice ? <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginTop: '10px' }}
                  onClick={() => approveWithMinimumPrice()}
                >
                  Approve with min. price
                </Button> : null
                }
              </Typography>
            </Box>
          </Modal>
      }

      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <Box style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30%',
          maxWidth: '60%',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '10px',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Product Quantity
            <CloseIcon style={{ cursor: 'pointer', float: 'right', color: 'red' }} onClick={() => handleCloseModal()} />
          </Typography>
          <br />
          <Typography id="modal-modal-description" sx={{ mb: 5 }}>
            <div>
              <TextField
                id="outlined-number"
                label="Edit Quantity"
                variant="outlined"
                value={quantity}
                onChange={(e) => handleQuantityChange(e)}
              />
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleEditQuantity()}
              >
                Submit
              </Button>

            </div>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openModal2}
        onClose={handleCloseModal2}
      >
        <Box style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '25%',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '10px',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Send back for revise
            <CloseIcon style={{ cursor: 'pointer', float: 'right', color: 'red' }} onClick={() => handleCloseModal2()} />
            <FormHelperText>You can set target price.</FormHelperText>
          </Typography>
          <br />
          <Typography id="modal-modal-description" sx={{ mb: 5 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',

              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={priceCheck}
                    onChange={(e) => handlePriceCheck(e)}
                  />
                }
                label="Target Price"
              />
              {
                priceCheck &&
                <div>
                  <TextField
                    style={{
                      // width: '250px',
                    }}
                    id="outlined-number"
                    label="Target Price"
                    type="number"
                    variant="outlined"
                    value={csPriceTarget}
                    onChange={(e) => handleCSPriceTarget(e)}
                  />
                </div>
              }
            </div>
            <div style={{ float: 'right', marginRight: '22%', marginTop: '15px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleTargetPrice()}
              >Submit</Button>
            </div>
          </Typography>
        </Box>
      </Modal>

      {/* Reject Requisition */}
      <Modal
        open={openModal3}
        onClose={handleCloseModal3}
      >
        <Box style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30%',
          maxWidth: '60%',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '10px',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reject Requisition
            <CloseIcon style={{ cursor: 'pointer', float: 'right', color: 'red' }} onClick={() => handleCloseModal3()} />
          </Typography>
          <br />
          <Typography id="modal-modal-description" sx={{ mb: 5 }}>
            <div>
              <TextField
                id="outlined-number"
                label="Reason"
                variant="outlined"
                style={{ width: '100%' }}
                value={reason}
                onChange={(e) => handleReason(e)}
              />
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={handleRejectItemCS}
                style={{ float: 'right' }}
              >
                Submit
              </Button>

            </div>
          </Typography>
        </Box>
      </Modal>

      {/* View Approve History */}
      <Modal
        open={openModal4}
        onClose={handleOpenModal4}
      >
        <Box style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '40%',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '10px',
        }}>
          <Typography variant="h6">
            View Approved History
            <CloseIcon style={{ cursor: 'pointer', float: 'right', color: 'red' }} onClick={() => handleCloseModal4()} />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mb: 5 }}>
            {
              isLoading ? (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Counter />
                </div>
              ) : (
                approvedHistory.length > 0 ? (
                  <table style={{ width: '100%' }} className="table">
                    <thead style={{ textAlign: 'center' }} className="thead">
                      <tr>
                        <th className="th" style={{ border: '1px solid black' }}>S/N</th>
                        <th className="th" style={{ border: '1px solid black' }}>Approver's Name</th>
                        <th className="th" style={{ border: '1px solid black' }}>Hold Days</th>
                        <th className="th" style={{ border: '1px solid black' }}>Date</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {
                        approvedHistory?.map((history, index) => {
                          return (
                            <tr key={index}>
                              <td className="td">{index + 1}</td>
                              <td className="td">{history.approver_name}</td>
                              <td className="td">{history.hold}</td>
                              <td className="td">{moment(history.updated_at).format('DD-MM-YYYY')}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                ) : (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h2>
                      No Approved History Found.
                    </h2>
                  </div>
                )
              )
            }
          </Typography>
        </Box>
      </Modal>

    </>
  );
};
