import React from "react";
import "./Spinner.css";

export default function LoadingSpinner() {
  return (
    <div>
      <div className="spinner">
        <div className="spinner-text">
          Loading....
        </div>
        <div className="spinner-component red">
        </div>
        <div className="spinner-component green">
        </div>
        <div className="spinner-component blue">
        </div>
      </div>
    </div>

  );
}